<script setup lang="ts">
import { computed } from 'vue'
import { useTheme } from 'vuetify'
import { useI18n } from 'vue-i18n'
import PolicyWidget from '@kidzonet/dashboard-overview-policy-widget'
import { getPolicyName, getPolicyDescription } from '@kidzonet/use-policy'

interface PolicyItemProps {
    name?: string
    isActive?: boolean
    subheader?: string
    hideTooltip?: boolean
}

const props = withDefaults(defineProps<PolicyItemProps>(), {
    name: '',
    hideTooltip: false,
})

const theme = useTheme()

const { t } = useI18n()

const classList = computed(() => {
    const result = ['policy-item']
    if (props.isActive) {
        result.push('policy-item--active')
    }
    if (theme.global.current.value.dark) {
        result.push('policy-item--dark')
    }
    return result
})

const tooltipConfig = computed(() => {
    if (props.hideTooltip) {
        return {
            openOnHover: false,
            persistent: false,
        }
    }
    return {
        text: props.subheader || getPolicyDescription(props.name),
        location: 'bottom',
        maxWidth: '400',
        openOnHover: false,
        persistent: false,
        closeDelay: 300,
        openOnClick: true,
    }
})

const subheaderInPolicyDesription = computed(() => {
    if (props.hideTooltip) {
        return props.subheader || getPolicyDescription(props.name)
    }
    return ""
})
</script>

<template>
    <PolicyWidget
        :header="getPolicyName(name)"
        :subheader="subheaderInPolicyDesription"
        :class="classList"
        v-ripple
        v-tooltip="tooltipConfig"
    >
        <template #icon>
            <slot name="icon" />
        </template>
        <template #action>
            <slot />
        </template>
        <slot name="bottom" />
    </PolicyWidget>
</template>

<style>
    .policy-item {
        --active-border-color: #C0BCE5;
        --active-background-color: #FCFBFE;

        cursor: pointer;
        border: 1px solid #E4E4E4;

        &--dark {
            --active-border-color: inherit;
            --active-background-color: #000;
        }

        &:hover, &--active {
            background-color: var(--active-background-color) !important;
            border-color: var(--active-border-color);
        }

        @media(max-width: 1100px) {
            block-size: auto;
        }
    }

    .policy-item__icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        min-inline-size: 48px;
        min-block-size: 48px;

        border-radius: 12px;
    }
</style>
